import {
    INITIALIZE_CONTENT_PAGE,
    RESET_CONTENT_PAGE,
    UPDATE_CONTENT_ELAPSED_TIME,
    UPDATE_CONTENT_PAGE,
    SET_CURRENT_CONTENT_TO_BE_SHARED,
} from '../types/content'
import cloneDeep from 'lodash/cloneDeep'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    contentData: {},
}

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                contentData: action.payload?.contentReducer?.contentData,
            }
        case INITIALIZE_CONTENT_PAGE:
            return {
                ...state,
                contentData: action.payload,
            }
        case UPDATE_CONTENT_PAGE:
            return {
                ...state,
                contentData: action.payload,
            }
        case RESET_CONTENT_PAGE:
            return {
                ...state,
                contentData: {},
            }

        case UPDATE_CONTENT_ELAPSED_TIME: {
            const contentDetails = cloneDeep(state.contentData)

            if (contentDetails && contentDetails.id === action.payload.id) {
                contentDetails.meta.timeElapsed = action.payload.timeElapsed
            }

            return {
                ...state,
                contentData: contentDetails,
            }
        }

        case SET_CURRENT_CONTENT_TO_BE_SHARED: {
            return {
                ...state,
                currentContentToBeShared: action.payload,
            }
        }

        default:
            return state
    }
}

export default contentReducer
